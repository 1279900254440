import React from "react";

function CardSubD({ title }) {
  return (
    <div className="card-sub-d">
      <div className="card-content-sub-d">
        <h2 className="card-title-sub-d">{title}</h2>
      </div>
    </div>
  );
}

export default CardSubD;
