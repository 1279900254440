import React from "react";

function CardSubC({ title }) {
  return (
    <div className="card-sub-c">
      <div className="card-content-sub-c">
        <h2 className="card-title-sub-c">{title}</h2>
      </div>
    </div>
  );
}

export default CardSubC;
