import React from "react";

function CardSubA({ title }) {
  return (
    <div className="card-sub-a">
      <div className="card-content-sub-a">
        <h2 className="card-title-sub-a">{title}</h2>
      </div>
    </div>
  );
}

export default CardSubA;
