import React from "react";

function CardSubB({ title }) {
  return (
    <div className="card-sub-b">
      <div className="card-content-sub-b">
        <h2 className="card-title-sub-b">{title}</h2>
      </div>
    </div>
  );
}

export default CardSubB;
